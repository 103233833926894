import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import ProductCenter from '../views/ProductCenter.vue'
import ComDesc from '../views/ComDesc.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    redirect: '/home'
  },
  /* {
    path: '/',
    name: 'home',
    component: HomeView
  }, */
  {
    // 首页
    path: '/home',
    name: 'homeView',
    component: HomeView
  },
  /* {
    // 首页
    path: '/home',
    name: 'homeView',
    component: () => import('../views/HomeView.vue')
  }, */
  {
    // 公司介绍
    path: '/comDesc',
    name: 'comDesc',
    component: ComDesc
    // component: () => import('../views/ComDesc.vue')
  },
  /*  {
     // 产品中心
     path: '/productCenter',
     name: 'productCenter',
     component: ProductCenter
     // component: () => import('../views/ProductCenter.vue')
   }, */
  {
    // 产品中心各个改动
    path: '/ProductCenterDec',
    name: 'ProductCenterDec',
    // component: ProductCenterDec
    component: () => import('../views/ProductCenterDec.vue')
  },
  {
    // 产品详情
    path: '/SingleProduct',
    name: 'SingleProduct',
    // component: SingleProduct
    component: () => import('../views/SingleProduct.vue')
  },
  {
    // 技术文章
    path: '/TechTxt',
    name: 'TechTxt',
    // component: SingleProduct
    component: () => import('../views/TechTxt.vue')
  },
  {
    // 技术文章详情
    path: '/TechTxtIn',
    name: 'TechTxtin',
    // component: SingleProduct
    component: () => import('../views/TechTxtIn.vue')
  },
  {
    // 人才招聘
    path: '/RecRuit',
    name: 'RecRuit',
    // component: SingleProduct
    component: () => import('../views/RecRuit.vue')
  },
  {
    // 联系我们
    path: '/ContactUs',
    name: 'ContactUs',
    // component: SingleProduct
    component: () => import('../views/ContactUs.vue')
  },
  /*{
    path: '/:pathMatch(.*)*',
    redirect: '/home'
  }, */
  /* {
    path: '*',
    redirect: '/'
  }, */
  {
    path: '*',
    // redirect: '/',
    redirect: '/home'
    // component: HomeView
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  /*  scrollBehavior(to, from, savedPosition) {
     // return 期望滚动到哪个的位置
     to, from,savedPosition;
     return { x: 0, y: 0 }
   } */
})
router.beforeEach((to, form, next) => {
  window.scroll(0, 0);
  next()
})

export default router
