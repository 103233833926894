<template>
  <div class="home">
    <!-- 首页 -->
    <div class="bannerImg"><img :src="require('../assets/shouye.png')" alt="" /></div>
    <h1 class="homeTitle">关于德恒丰</h1>
    <div class="comBriefDesc">
      <img :src="require('../assets/city.jpg')" alt="" />
      <h3>深圳市德恒丰科技发展有限公司</h3>
      <h4>SHENZHEN DE HENG FENG TECHNOLOGY DEVELOPMENT Ltd.</h4>
      <p>
        深圳市德恒丰科技发展有限公司创立于2007年，是华南地区知名的专业医疗设备供应商，公司地处深圳市福田区CBD商务中心区，旨在为医院及相关医疗机构提供世界一流的医疗器械产品。
        公司销售网络遍布本市和国内部分省市，与深圳市的20多家重点医疗卫生单位建立了良好稳定的合作关系...
      </p>
      <button>查看详情 &gt;</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
};
</script>
<style lang="scss" scoped>
.home {
  line-height: 1.2;
  margin-top: 68px;
  .bannerImg {
    img {
      width: 100%;
    }
  }
  .homeTitle {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 52px;
    font-size: 38px;
    font-weight: bold;
  }
  .comBriefDesc {
    width: 1200px;
    height: 246px;
    margin: 0 auto;
    margin-bottom: 100px;
    position: relative;
    padding-left: 28px;

    img {
      width: 403px;
      height: 246px;
      float: right;
      &::before {
        content: "";
        display: block;
        clear: both;
      }
      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
    h4 {
      font-size: 15px;
      color: #999;
      margin: 15px 0;
    }
    p {
      display: inline-block;
      width: 740px;
      color: #999;
      margin-top: 30px;
    }
    button {
      width: 110px;
      height: 30px;
      background-color: #216bcc;
      color: white;
      border: none;
      outline: none;
      position: absolute;
      bottom: 0;
      left: 28px;
      border-radius: 3px;
      font-size: 14px;
    }
  }
}
</style>
