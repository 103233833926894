<template>
  <div class="ComDesc">
    <!-- 公司介绍 -->
    <div class="bannerImg"><img :src="require('@/assets/shouye.png')" alt="" /></div>
    <div class="aboutUsTxt">
      <h1>公司简介</h1>
      <p>
        深圳市德恒丰科技发展有限公司创立于2007年，是华南地区知名的专业医疗设备供应商，公司地处深圳市福田区CBD商务中心区，旨在为医院及相关医疗机构提供世界一流的医疗器械产品；公司从业人员60%为专科以上学历，专业素质良好，设有专职工程师4名，全力精心解决公司售出产品的安装、培训、售后维修等问题，确保用户满意；公司销售网络遍布本市和国内部分省市，与深圳市的20多家重点医疗卫生单位建立了良好稳定的合作关系，在行业内具有良好的客户资源和商业信誉；我们愿与更多优秀的品牌建立牢固的合作关系，求实创新，不断提升和完善自身的管理与服务，与合作伙伴实现更大的互利与共赢！&nbsp;
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ComDesc",
  components: {},
};
</script>
<style lang="scss" scoped>
.ComDesc {
  line-height: 1.2;
  margin-top: 68px;
  .bannerImg {
    img {
      width: 100%;
    }
  }
  .aboutUsTxt {
    width: 62.5%;
    background-image: url("@/assets/comdescBg.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    text-align: center;
    padding: 50px 50px;
    margin: 100px auto;
    h1 {
      color: white;
      margin: 0 auto;
      padding-bottom: 50px;
      font-size: 2.5rem;
      font-weight: 500;
    }
    p {
      color: white;
      font-size: 17px;
      text-align: justify;
      text-indent: 50px;
      margin-top: 0;
      margin-bottom: 1rem;
      line-height: 1.5;
    }
  }
}
</style>
