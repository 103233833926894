<template>
  <div id="app">
    <header class="fixed">
      <div class="header">
        <div class="logo">
          <img src="@/assets/headerLogo.png" alt="" /><span class="hotLine"
            >24小时热线：13006665968</span
          >
        </div>
        <nav>
          <div style="display: inline-block">
            <router-link to="/home" @click.native="hoverNavactive = ''" class="link_item"
              >首页</router-link
            >
          </div>
          <div style="display: inline-block">
            <router-link
              to="/comDesc"
              @click.native="hoverNavactive = ''"
              class="link_item"
              >公司介绍</router-link
            >
          </div>
          <div
            style="display: inline-block; position: relative"
            class="link_item_productCenter"
          >
            <div
              :class="{ 'router-link-exact-active': hoverNavactive != '' }"
              class="link_item"
            >
              产品中心
            </div>
            <div class="subNav subNav_hover">
              <li
                @click.stop="hoverNavli('title1')"
                :class="{ active: hoverNavactive == 'title1' }"
              >
                飞利浦AED、除颤监护仪系列产品
              </li>
              <li
                @click.stop="hoverNavli('title2')"
                :class="{ active: hoverNavactive == 'title2' }"
              >
                博声心电、血压产品系列
              </li>
              <li
                @click.stop="hoverNavli('title3')"
                :class="{ active: hoverNavactive == 'title3' }"
              >
                杰纳瑞心率变异分析仪
              </li>
              <li
                @click.stop="hoverNavli('title4')"
                :class="{ active: hoverNavactive == 'title4' }"
              >
                怡和嘉业睡眠呼吸系列产品
              </li>
              <li
                @click.stop="hoverNavli('title5')"
                :class="{ active: hoverNavactive == 'title5' }"
              >
                怡和嘉业制氧机
              </li>
              <li
                @click.stop="hoverNavli('title9')"
                :class="{ active: hoverNavactive == 'title9' }"
              >
                鱼跃血压、吸引器系列产品
              </li>
              <li
                @click.stop="hoverNavli('title10')"
                :class="{ active: hoverNavactive == 'title10' }"
              >
                美好创亿医疗肺功能系列产品
              </li>
              <li
                @click.stop="hoverNavli('title7')"
                :class="{ active: hoverNavactive == 'title7' }"
              >
                其他产品系列
              </li>
              <li
                @click.stop="hoverNavli('title6')"
                :class="{ active: hoverNavactive == 'title6' }"
              >
                耗材
              </li>
              <li
                v-if="false"
                @click.stop="hoverNavli('title8')"
                :class="{ active: hoverNavactive == 'title8' }"
              >
                制氧机
              </li>
            </div>
          </div>
          <div style="display: inline-block">
            <router-link
              to="/TechTxt"
              @click.native="hoverNavactive = ''"
              class="link_item"
              >技术文章</router-link
            >
          </div>
          <div style="display: inline-block">
            <router-link
              to="/RecRuit"
              @click.native="hoverNavactive = ''"
              class="link_item"
              >人才招聘</router-link
            >
          </div>
          <div style="display: inline-block">
            <router-link
              to="/ContactUs"
              @click.native="hoverNavactive = ''"
              class="link_item"
              >联系我们</router-link
            >
          </div>
        </nav>
      </div>
    </header>
    <!-- <keep-alive > -->
    <router-view @clea="fwe" />
    <!-- </keep-alive> -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-3" style="position: relative">
            <img src="@/assets/aboutme.png" alt="" class="aboutUsImg" />
            <div class="divLine"></div>
          </div>
          <div class="col-md-9 col-sm-9">
            <ul>
              <li>地址：深圳市福田区彩田南路福建大厦B座504室</li>
              <li>电话：0755-82993620 13006665968</li>
              <li>传真：0755-82993520</li>
              <li>邮箱：szdhf168@126.com</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    <div class="copyright text-center">
      <p
        class="copyright"
        style="
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          margin: 0px auto;
        "
      >
        Copyright©2020-2024 深圳市德恒丰科技发展有限公司
        <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备20032849号</a>
      </p>
      <div class="container">
        <div class="row"></div>
      </div>
    </div>
    <!-- <div style="text-align: center" class="footerCopy">
      Copyright©2020 深圳市德恒丰科技发展有限公司
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备20032849号</a>
    </div> -->
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "ProductCenter",
  components: {},
  data() {
    return {
      hoverNavactive: "",
    };
  },
  methods: {
    hoverNavli(str) {
      if (this.hoverNavactive == str) return;
      this.hoverNavactive = str;
      this.$router.push({
        path: "ProductCenterDec",
        query: { Dectitle: str },
      });
    },
    fwe(str) {
      this.hoverNavactive = str;
    },
  },
  updated() {
    if (this.$route.name == "ProductCenterDec") {
      this.hoverNavactive = this.$route.query.Dectitle;
    } else {
      this.hoverNavactive = "";
    }
  },
  mounted() {
    console.log("啦啦啦啦啦啦啦啦啦");
  },
};
</script>

<style lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  min-width: 1200px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.fixed {
  width: 100%;
  height: 68px;
  background-color: #f7f7f7;
  z-index: 99;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
}
.header {
  width: 1200px;
  height: 68px;
  z-index: 99;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    height: 68px;
    display: flex;
    align-items: center;
    position: relative;
    .hotLine {
      position: relative;
      top: 5px;
      margin-left: 13px;
      color: gray;
    }
  }
  .link_item {
    display: inline-block;
    width: 73px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    color: black;
    font-size: 15px;
    position: relative;
  }
  .subNav {
    display: none;
    // width: 200px;
    width: 280px;
    background-color: #eeeeee;
    position: absolute;
    left: calc((200px - 73px) / -2);
    top: 68px;
    padding: 10px 15px;
    li {
      line-height: 30px;
      list-style-type: none;
      cursor: pointer;
      margin-bottom: 7px;
      &:hover {
        background-color: #d1d9e4;
      }
      &.active {
        background-color: #d1d9e4;
      }
    }
  }
  .link_item_productCenter:hover {
    .subNav_hover {
      display: inline-block;
    }
  }
}
.router-link-exact-active {
  border-bottom: 4px solid #1e68c9;
}
nav {
  // padding: 30px;
  a {
    box-sizing: border-box;
    // font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.footer {
  background: #eeeeee;
  padding: 50px 0px;
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      .aboutUsImg {
        width: 101px;
        height: 93px;
        vertical-align: middle;
        border-style: none;
        // position: relative;
        // top: 50%;
        // margin-top: -46.5px;
        // left: 50%;
        // margin-left: -55.5px;
      }
      .divLine {
        display: inline-block;
        width: 1px;
        height: 100px;
        background-color: black;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .col-md-3 {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
      }
      .col-md-9 {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
      }
      ul {
        li {
          list-style-type: none;
          margin: 5px 0;
        }
      }
    }
  }
}
.copyright {
  background: #0e0e0e;
  padding: 10px 0px;
  color: #fff;
  font-family: "raleway", sans-serif;
  text-align: center;
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  a {
    text-decoration: none;
    transition: 0.3s all ease-in-out;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
}
.footerCopy {
  a {
    text-decoration: none;
    transition: 0.3s all ease-in-out;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
}
@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
    min-width: 960px;
  }
  .aboutUsImg {
    position: relative;
    top: 50%;
    margin-top: -46.5px;
    left: 50%;
    margin-left: -55.5px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
    min-width: 960px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
b,
strong {
  font-weight: bolder;
}
button,
input {
  overflow: visible;
}
</style>
