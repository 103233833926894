import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// if (process.env.NODE_ENV !== 'production'){//生产打包
if (process.env.NODE_ENV !== 'development') {//开发
  //   console.log("production")
  Vue.config.productionTip = false//设置为 false 只会影响 Vue 在生产环境中的一些提示信息，不会直接影响到 console 输出。设置这个配置项为 false 主要是为了去除开发阶段的一些非必要提示信息，以减小应用程序的体积和提升性能。
  // 因此，这个操作不会影响到 console.log()、console.warn() 等 console 方法的使用。你仍然可以在代码中使用 console 来输出调试信息和错误信息，不会受到 Vue.config.productionTip = false 的影响。
}
/* if (process.env.NODE_ENV !== 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
  // 这种方法也存在一些潜在的弊端：

  // 丢失调试信息：在生产环境下禁用 console.log 等方法会导致丢失控制台的调试信息，这可能会使问题排查变得困难，特别是在用户报告问题时。

  // 不利于维护：直接重写全局的 console 对象可能会导致代码不易维护，特别是当多人合作或是长期项目开发时。

  // 影响第三方库：有些第三方库可能依赖于 console 对象的原始行为，重写这些方法可能会导致这些库的功能异常。

  // 可能存在兼容性问题：某些浏览器或环境可能不支持对 console 对象的直接重写，这可能会导致意外的问题。
  console.log = function () { }
  console.error = function () { }
  console.dir = function () { }
} */
console.log("环境变量", process.env.NODE_ENV);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
